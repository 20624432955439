/* Skeleton.css */
.skeleton-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background: #f0f0f0;
    padding: 15px;
  }
  .skeleton-content {
    width: 769px;
    height: 932px;
    background: #ffffff;
    border-radius: 8px;
    padding: 15px;
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.1);
    transform: scale(1);
    transform-origin: center center;
  }
  .skeleton-row {
    margin-bottom: 15px;
    display: flex;
  }
  .skeleton-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 15px;
    margin-bottom: 15px;
  }
  .skeleton-element {
    background: #e0e0e0;
    border-radius: 4px;
    animation: pulse 0.7s infinite ease-in-out;
  }
  @keyframes pulse {
    0% {
      opacity: 0.3;
    }
    50% {
      opacity: 0.45;
    }
    100% {
      opacity: 0.6;
    }
  }