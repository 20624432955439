:root {
  --primary-color: #007AFF;
  --secondary-color: #5AC8FA;
  --background-color: #F2F2F7;
  --text-color: #000000;
  --border-color: #8a8a8a;
  --box-shadow: 0 5.36px 10.72px rgba(0, 0, 0, 0.1);
  --input-text-color: #444444;
  --ui-scale: 0.66;
}

.dark {
  --primary-color: #0A84FF;
  --secondary-color: #64D2FF;
  --background-color: #333333;
  --text-color: #FFFFFF;
  --border-color: #999999;
  --box-shadow: 0 5.36px 10.72px rgba(255, 255, 255, 0.05);
  --input-text-color: #FFFFFF;
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--background-color);
  color: var(--text-color);
  transition: all 0.3s ease;
  font-size: calc(16px * var(--ui-scale));
}

.App {
  max-width: calc(1200px * var(--ui-scale));
  margin: calc(10px * var(--ui-scale)) auto;
  padding: calc(20px * var(--ui-scale));
  background-color: var(--background-color);
  border-radius: calc(20px * var(--ui-scale));
  box-shadow: var(--box-shadow);
  transition: all 0.5s ease-out;
  animation: fadeIn 1s ease-out;
}

.header {
  margin-bottom: calc(30px * var(--ui-scale));
  width: 100%;
  animation: fadeIn 1s ease-out;
  
}

.header-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo-title {
  display: flex;
  flex-direction: column;
}

.made-by {
  font-size: calc(1.8em * var(--ui-scale));
  color: var(--text-color);
  opacity: 0.7;
  margin-bottom: calc(10px * var(--ui-scale));
  margin-left: calc(10px * var(--ui-scale));
}

.logo-title-inner {
  display: flex;
}

.app-icon {
  width: calc(90px * var(--ui-scale));
  height: calc(90px * var(--ui-scale));
  margin-right: calc(10px * var(--ui-scale));
}

.header-controls {
  display: flex;
  gap: calc(10px * var(--ui-scale));
  flex-direction: row;
  justify-content: end;
  flex-wrap: wrap;
  width: 100%;
}

h1, h2, h3 {
  color: var(--primary-color);
  margin: 0;
}

h1 {
  font-size: calc(4em * var(--ui-scale));

}

h2 {
  font-size: calc(1.5em * var(--ui-scale));
  font-weight: 700;
}

.dark h1 {
  color: #64D2FF;
}

.input-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: calc(50px * var(--ui-scale));
  animation: fadeIn 1s ease-out;
  margin-left: 5px;
  margin-right: 5px;
}

.weight-input-wrapper {
  margin-right: calc(20px * var(--ui-scale));
}

.weight-input-form {
  display: flex;
  flex-direction: column;
  gap: calc(10px * var(--ui-scale));
  border-radius: 30px;
  padding: 20px;
  box-shadow: 0 0 calc(20px* var(--ui-scale)) rgba(0, 0, 0, 0.1);
}

.weight-input-container {
  display: flex;
  align-items: center;
  gap: calc(10px * var(--ui-scale));
}

.weight-input-container input[type="number"] {
  text-align: center;
  border: calc(2.4px * var(--ui-scale)) solid var(--border-color);
  font-weight: bold;
  font-size: calc(2rem * var(--ui-scale));
  border-radius: calc(30px * var(--ui-scale));
  width: calc(100px * var(--ui-scale));
  height: 30px;
}

.dark .weight-input-container input[type="number"] {
  border: calc(2px * var(--ui-scale)) solid #64D2FF;
}

.weight-input-container .submit-button {
  border: calc(3px * var(--ui-scale)) solid var(--border-color);
  font-weight: bold;
  font-size: calc(25px * var(--ui-scale));
  border-radius: calc(30px * var(--ui-scale));
}

.dark .weight-input-container .submit-button {
  border: calc(3px * var(--ui-scale)) solid #64D2FF;
}

.goal-weight-container {
  display: flex;
  align-items: center;
  gap: calc(10px * var(--ui-scale));
  background-color: var(--background-color);
  padding: calc(10px * var(--ui-scale)) calc(20px * var(--ui-scale));
  border-radius: calc(20px * var(--ui-scale));
  box-shadow: 0 5px calc(25px* var(--ui-scale)) rgba(0, 0, 0, 0.15);
}

.goal-weight-container:hover {
  box-shadow: 0 calc(4px * var(--ui-scale)) calc(8px * var(--ui-scale)) rgba(0, 0, 0, 0.15);
  transform: translateY(calc(-2px * var(--ui-scale)));
}

.goal-weight-container label {
  font-weight: 600;
  color: var(--primary-color);
  font-size: calc(2.3em * var(--ui-scale));
  text-align: center;
}

.dark .goal-weight-container label {
  color: #64D2FF;
}

.goal-weight-input, .current-weight-input {
  width: calc(78px * var(--ui-scale));
  padding: calc(10px * var(--ui-scale));
  font-size: calc(1.56em * var(--ui-scale));
  border: calc(2px * var(--ui-scale)) solid var(--border-color);
  border-radius: calc(20px * var(--ui-scale));
  outline: none;
  transition: all 0.3s ease;
  background-color: var(--background-color);
  color: var(--input-text-color);
  font-weight: bold;
  text-align: center;
}


.goal-weight-input:focus {
  border-color: var(--primary-color);
  box-shadow: 0 0 0 calc(3px * var(--ui-scale)) rgba(74, 144, 226, 0.2);
}

button {
  background-color: var(--primary-color);
  border: none;
  color: white;
  padding: calc(12px * var(--ui-scale)) calc(24px * var(--ui-scale));
  font-size: calc(16px * var(--ui-scale));
  border-radius: calc(20px * var(--ui-scale));
  cursor: pointer;
  transition: all 0.3s ease;
  text-transform: uppercase;
  letter-spacing: calc(1px * var(--ui-scale));
}

button:hover {
  background-color: var(--secondary-color);
  transform: translateY(calc(-2px * var(--ui-scale)));
  box-shadow: 0 calc(4px * var(--ui-scale)) calc(8px * var(--ui-scale)) rgba(0, 0, 0, 0.1);
}

button:active {
  transform: translateY(0);
}

input[type="number"] {
  -moz-appearance: textfield;
  appearance: textfield;
  font-family: inherit;
  font-size: calc(2.2em * var(--ui-scale));
  color: var(--input-text-color);
  background-color: var(--background-color);
  border: calc(1px * var(--ui-scale)) solid var(--border-color);
  border-radius: calc(20px * var(--ui-scale));
  padding: calc(8px * var(--ui-scale)) calc(12px * var(--ui-scale));
  width: calc(73px * var(--ui-scale));
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
  font-weight: bold;
  text-align: center;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"]:focus {
  outline: none;
  border-color: var(--primary-color);
  box-shadow: 0 0 0 calc(2px * var(--ui-scale)) rgba(0, 122, 255, 0.2);
}

.time-range-selector-container {
  background-color: var(--background-color);
  border-radius: calc(25px * var(--ui-scale));
  box-shadow: 0 0 calc(20px* var(--ui-scale)) rgba(0, 0, 0, 0.1);
  padding: calc(5px * var(--ui-scale));
  margin-bottom: 0px;
  display: inline-flex;
  animation: fadeIn 1s ease-out;
}

.time-range-button {
  cursor: pointer;
  padding: calc(8px * var(--ui-scale)) calc(16px * var(--ui-scale));
  border-radius: calc(20px * var(--ui-scale));
  background-color: var(--background-color);
  transition: all 0.3s ease;
  font-weight: 600;
  border: none;
  color: var(--text-color);
  font-size: calc(15px * var(--ui-scale));
}

.time-range-button.active {
  background-color: var(--primary-color);
  color: white;
}

.time-range-button:hover {
  background-color: var(--primary-color);
  color: white;
}

.clear-all-button {
  background-color: #FF3B30;
  color: white;
  transition: all 0.3s ease;
  margin: calc(40px * var(--ui-scale)) auto 10px auto;
  display: block;
  animation: fadeIn 1s ease-out;
}

.clear-all-button:hover {
  background-color: #FF453A;
}

.prediction {
  text-align: center;
  margin: calc(20px * var(--ui-scale)) auto;
  background-color: var(--background-color);
  padding: calc(15px * var(--ui-scale));
  border-radius: calc(8px * var(--ui-scale));
  box-shadow: var(--box-shadow);
  max-width: 100%;
  animation: fadeIn 1s ease-out;
}

.prediction-row {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: calc(15px * var(--ui-scale));
}

.prediction-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: calc(10px * var(--ui-scale));
  padding: calc(10px * var(--ui-scale));
  font-size: calc(2.2em * var(--ui-scale));
  background-color: var(--background-color);
  border-radius: calc(10px * var(--ui-scale));
  box-shadow: 0 calc(5px * var(--ui-scale)) calc(5px * var(--ui-scale)) rgba(0, 0, 0, 0.1);
}

.prediction-item span:first-child {
  font-weight: 600;
}

.prediction-item span:last-child {
  font-weight: bold;
  color: var(--primary-color);
}

.dark .prediction-item span:last-child {
  color: #64D2FF;
}

.graph-container, .bar-graph-container {
  width: 100%;
  background: var(--background-color);
  border-radius: calc(16px * var(--ui-scale));
  box-shadow: var(--box-shadow);
  margin: calc(10px * var(--ui-scale)) auto;
  max-width: calc(1200px * var(--ui-scale));
  padding-bottom: calc(20px * var(--ui-scale));
  padding-top: calc(23px * var(--ui-scale));
}

.graph-container {
  height: calc(400px * var(--ui-scale));
}

.bar-graph-container {
  height: calc(200px * var(--ui-scale));
}

.graph-wrapper, .bar-graph-wrapper {
  width: 100%;
  height: 100%;
}

.theme-toggle {
  background: none;
  border: none;
  cursor: pointer;
  font-size: calc(24px * var(--ui-scale));
  display: flex;
  align-items: center;
  justify-content: center;
  max-height: calc(40px * var(--ui-scale));
}

.save-load-buttons {
  display: flex;
  gap: calc(10px * var(--ui-scale));
}

.save-button,
.load-button {
  background-color: var(--primary-color);
  color: white;
  border: none;
  padding: 0 calc(16px * var(--ui-scale));
  border-radius: calc(20px * var(--ui-scale));
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.1s ease;
  font-size: calc(14px * var(--ui-scale));
  max-height: calc(40px * var(--ui-scale));
  display: flex;
  align-items: center;
  justify-content: center;
}

.save-button:hover,
.load-button:hover {
  background-color: var(--secondary-color);
}

.save-button:active,
.load-button:active {
  transform: translateY(calc(1px * var(--ui-scale)));
}

.react-datepicker-wrapper {
  z-index: 1000;
}

.react-datepicker__input-container input {
  font-size: calc(2.1em * var(--ui-scale));
  color: var(--input-text-color);
  background-color: var(--background-color);
  border: calc(1px * var(--ui-scale)) solid var(--border-color);
  border-radius: calc(20px * var(--ui-scale));
  padding: calc(8px * var(--ui-scale)) calc(12px * var(--ui-scale));
  width: calc(120px * var(--ui-scale));
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
  font-weight: bold;
  text-align: center;
}

.react-datepicker__input-container input:focus {
  outline: none;
  border-color: var(--primary-color);
  box-shadow: 0 0 0 calc(2px * var(--ui-scale)) rgba(0, 122, 255, 0.2);
}

.react-datepicker__month-container {
  background-color: var(--background-color);
  border: calc(1px * var(--ui-scale)) solid var(--border-color);
  border-radius: calc(8px * var(--ui-scale));
  box-shadow: var(--box-shadow);
}

.react-datepicker__header {
  background-color: var(--primary-color);
  border-bottom: none;
}

.react-datepicker__current-month,
.react-datepicker__day-name {
  color: white;
}

.react-datepicker__day {
  color: var(--text-color);
}

.react-datepicker__day:hover {
  background-color: var(--secondary-color);
}

.react-datepicker__day--selected {
  background-color: var(--primary-color);
  color: white;
}

.date-picker-container {
  display: flex;
  align-items: center;
  gap: calc(5px * var(--ui-scale));
  margin: 3px;
}

.language-selector {
  width: calc(150px * var(--ui-scale));
}

.language-selector .react-select__menu {
  max-height: none;
  overflow-y: visible;
}

.css-mpngnc-control {
  background-color: var(--background-color) !important;
  border-color: var(--border-color) !important;
}

.dark .css-mpngnc-control {
  background-color: #2a2a2a !important;
  border-color: #555 !important;
}

.css-b62m3t-container {
  color: var(--text-color) !important;
}

.css-pbe6xf-menu {
  background-color: var(--background-color) !important;
}

.dark .css-pbe6xf-menu {
  background-color: #2a2a2a !important;
}

.css-pbe6xf-menu::-webkit-scrollbar {
  width: calc(5px * var(--ui-scale));
}

.css-pbe6xf-menu::-webkit-scrollbar-track {
  background: var(--background-color);
  border-radius: calc(10px * var(--ui-scale));
}

.css-pbe6xf-menu::-webkit-scrollbar-thumb {
  background: var(--primary-color);
  border-radius: calc(10px * var(--ui-scale));
}

.dark .css-pbe6xf-menu::-webkit-scrollbar-thumb {
  background: #64D2FF;
}

.confirmation-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  animation: fadeIn 0.3s ease-out;
}

.confirmation-modal .modal-content {
  background-color: var(--background-color);
  padding: calc(20px * var(--ui-scale));
  border-radius: calc(10px * var(--ui-scale));
  box-shadow: 0 calc(4px * var(--ui-scale)) calc(6px * var(--ui-scale)) rgba(0, 0, 0, 0.1);
  text-align: center;
}

.confirmation-modal .modal-buttons {
  display: flex;
  justify-content: space-around;
  margin-top: calc(20px * var(--ui-scale));
}

.confirmation-modal button {
  padding: calc(15px * var(--ui-scale)) calc(25px * var(--ui-scale));
  border: none;
  border-radius: calc(5px * var(--ui-scale));
  cursor: pointer;
  transition: background-color 0.3s;
}

.confirmation-modal button:first-child {
  background-color: #ff4d4d;
  color: white;
}

.confirmation-modal button:last-child {
  background-color: #4caf50;
  color: white;
  
}

.confirmation-modal button{
  height: 35px;
}

.time-range-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: calc(20px * var(--ui-scale));
  flex-wrap: wrap;
  animation: fadeIn 1s ease-out;
}

.total-days {
  font-size: calc(1.7em * var(--ui-scale));
  font-weight: bold;
  color: #999;
  letter-spacing: calc(1px * var(--ui-scale));
}

.confirmation-text {
  font-size: calc(2.5em * var(--ui-scale));
  margin-bottom: calc(50px * var(--ui-scale));
  margin-top: 10px;
}

@media (max-width: 630px) {
.goal-weight-container {
  width: 90%;
  justify-content: center;
  flex-direction: column;
}
}

@media (max-width: 1200px) {
  .App {
    margin: calc(0px * var(--ui-scale)) auto;
    padding: calc(20px * var(--ui-scale));
  }

  .graph-container,
  .bar-graph-container {
    padding: calc(10px * var(--ui-scale));
  }
}

@media (max-width: 525px) {
  .header-top {
    flex-direction: column;
    align-items: flex-start;
  }
  
  .logo-title-inner{
    margin-left: 10px;
  }

  .made-by{
    margin-left: 20px;
  }

  .input-container{
    margin-left: 0px;
  }

  .goal-weight-container label{
    font-size: calc(2.5em * var(--ui-scale));
  }

  .theme-toggle{
    padding: 10px;
    margin-right: 5px;
  }

  .header-controls {
    margin-top: calc(20px * var(--ui-scale));
    width: 100%;
    justify-content: center;
    flex-wrap: nowrap;
    gap: 0px;
  }

  .input-container {
    flex-direction: column-reverse;
    margin-bottom: 0px;
    align-items: center;
  }

  .weight-input-wrapper {
    margin-right: 0;
    margin-bottom: calc(20px * var(--ui-scale));
  }

  .weight-input-form {
    flex-direction: row;
    align-items: start;
    flex-wrap: wrap;
    
  }

  .goal-weight-container {
    width: 90%;
    justify-content: center;
    flex-direction: row;
    margin-bottom: 15px;
  }

  .time-range-selector-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .graph-container {
    height: calc(300px * var(--ui-scale));
  }

  .bar-graph-container {
    height: calc(150px * var(--ui-scale));
  }

  input[type="number"],
  .react-datepicker__input-container input {
    font-size: calc(20px * var(--ui-scale));
    padding: calc(10px * var(--ui-scale)) calc(14px * var(--ui-scale));
  }

  .prediction-row {
    grid-template-columns: repeat(2, 1fr);
  }

  .date-picker-container button {
    width: 35px;
    height: 33px;
  }
  
}

@media (max-width: 480px) {
  .App {
    padding: calc(5px * var(--ui-scale));
    width: 100%;
  }

  .weight-adjust-button{
    width: 65px;
    height: 50px;
  }

  .weight-adjust-container{
    width: 85%;
  }

  h1 {
    font-size: calc(3.5em * var(--ui-scale));
  }
  
  .total-days{
    font-size: calc(2.1em * var(--ui-scale));
  }

  .date-picker-container{
    justify-content: center;
  }

  .goal-weight-container label {
    font-size: calc(2.5em * var(--ui-scale));
  }

  .goal-weight-input, .current-weight-input {
    width: calc(60px * var(--ui-scale));
    font-size: calc(1.2em * var(--ui-scale));
  }

  .graph-container {
    height: calc(400px * var(--ui-scale));
    width: 98%;
  }

  .bar-graph-container {
    height: calc(200px * var(--ui-scale));
    width: 98%;
  }

  input[type="number"],
   input {
    font-size: calc(24px * var(--ui-scale));
    padding: calc(8px * var(--ui-scale)) calc(12px * var(--ui-scale));
    width: 50px;
  }

  .time-range-button{
    font-size: calc(18px * var(--ui-scale));
  }

  .prediction-row {
    grid-template-columns: 1fr;
  }

  .prediction-item {
    font-size: calc(1.9em * var(--ui-scale));
    padding: calc(8px * var(--ui-scale));
  }

  .weight-input-form {
    flex-direction: row;
    align-items: stretch;
    flex-wrap: wrap;
  }

  .weight-input-container {
    flex-direction: column;
  }

  .weight-input-container input[type="number"],
  .weight-input-container input[type="date"] {
    width: 90%;
  }

  .weight-input-container .submit-button {
    width: 100%;
    margin-top: calc(10px * var(--ui-scale));
  }

  .prediction-row {
    display: flex;
    flex-direction: column;
  }

  .prediction-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
  }

  .prediction-item span:first-child {
    margin-right: 10px;
  }
}

.current-weight-input {
  cursor: default;
}

.weight-input-number {
  font-size: calc(1.6em * var(--ui-scale));
  border: calc(3px * var(--ui-scale)) solid var(--border-color);
  font-weight: bold;
  border-radius: calc(30px * var(--ui-scale));
}

@keyframes addWeight {
  0% { transform: scale(1); }
  50% { transform: scale(1.05); }
  100% { transform: scale(1); }
}

.animate-graph {
  animation: addWeight 0.5s ease-in-out;
}

.weight-input-wrapper button {
  transition: transform 0.3s ease-in-out;
}

.weight-input-wrapper button:active {
  transform: scale(0.95);
}

.dark {
  --primary-color: #0A74D1; /* Slightly darker blue */
  --secondary-color: #5AB3E6; /* Slightly darker blue */
  --background-color: #333333;
  --text-color: rgba(255, 255, 255, 0.85); /* 0.85% white */
  --border-color: #999999;
  --box-shadow: 0 5.36px 10.72px rgba(0, 0, 0, 0.3);
  --input-text-color: rgba(255, 255, 255, 0.85);
}

.dark h1 {
  color: #5AB3E6; /* Adjusted blue */
}

.dark h3 {
  color: #5AB3E6; /* Adjusted blue */
}

.dark .goal-weight-container label {
  color: #5AB3E6; /* Adjusted blue */
}

.dark .weight-input-container input[type="number"] {
  border: calc(2px * var(--ui-scale)) solid #5AB3E6; /* Adjusted blue */
}

.dark .weight-input-container .submit-button {
  border: calc(3px * var(--ui-scale)) solid #5AB3E6; /* Adjusted blue */
}

.dark .goal-weight-input, .dark .current-weight-input {
  color: rgba(255, 255, 255, 0.85);
}

.dark .prediction-item span:last-child {
  color: #5AB3E6; /* Adjusted blue */
}

.date-picker-container {
  display: flex;
  align-items: center;
  gap: calc(5px * var(--ui-scale));
}

.date-picker-container button {
  width: calc(44px * var(--ui-scale));
  height: calc(44px * var(--ui-scale));
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: calc(20px * var(--ui-scale));
  padding: 0;
  background-color: var(--primary-color);
  color: white;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.date-picker-container button:hover {
  background-color: var(--secondary-color);
}


.confirmation-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  animation: fadeIn 0.3s ease-out;
}

.confirmation-modal .modal-content {
  background-color: var(--background-color);
  padding: calc(30px * var(--ui-scale));
  border-radius: calc(20px * var(--ui-scale));
  box-shadow: 0 calc(4px * var(--ui-scale)) calc(6px * var(--ui-scale)) rgba(0, 0, 0, 0.1);
  text-align: center;
}

.confirmation-modal .modal-buttons {
  display: flex;
  justify-content: center;
  gap: calc(15px * var(--ui-scale));
  margin-top: calc(30px * var(--ui-scale));
  flex-wrap: wrap;
}

.confirmation-modal button {
  padding: calc(12px * var(--ui-scale)) calc(24px * var(--ui-scale));
  border: none;
  border-radius: calc(30px * var(--ui-scale));
  cursor: pointer;
  transition: background-color 0.3s, transform 0.1s;
  font-size: calc(16px * var(--ui-scale));
  font-weight: bold;
  min-width: calc(120px * var(--ui-scale));
}

.confirmation-modal button:hover {
  transform: translateY(calc(-2px * var(--ui-scale)));
}

.confirmation-modal button:active {
  transform: translateY(0);
}

.confirmation-modal button:first-child {
  background-color: #ff4d4d;
  color: white;
}

.confirmation-modal button:nth-child(2),
.confirmation-modal button:nth-child(3) {
  background-color: var(--primary-color);
  color: white;
}

.confirmation-modal button:last-child {
  background-color: #4caf50;
  color: white;
}

.confirmation-text {
  font-size: calc(3.5em * var(--ui-scale));
  margin-bottom: calc(50px * var(--ui-scale));
  color: var(--text-color);
}

@media (max-width: 525px) {
  .confirmation-modal .modal-buttons {
    flex-direction: column;
    align-items: stretch;
  }

  

  .confirmation-modal button {
    width: 100%;
    margin-bottom: calc(10px * var(--ui-scale));
  }

  
}


.congratulations-popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #ffffff;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  animation: popIn 0.5s ease-out;
  max-width: 90%;
  width: 300px; /* Adjust the width as necessary */
  text-align: center;
}

.congratulations-popup.dark {
  background-color: #333333;
  color: #ffffff;
}

.congratulations-content h2 {
  margin-bottom: 10px;
  color: #4caf50;
  font-size: 1rem;
}

.congratulations-popup.dark .congratulations-content h2 {
  color: #8bc34a;
}

.congratulations-content p {
  font-size: 18px;
}

@keyframes popIn {
  0% {
    transform: translate(-50%, -50%) scale(0.5);
    opacity: 0;
  }
  100% {
    transform: translate(-50%, -50%) scale(1);
    opacity: 1;
  }
}

.fireworks {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 9999;
}


.goal-weight-container {
  position: relative;
  padding-bottom: 20px; /* Add some space for the progress bar */
}

.goal-progress-bar-container {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 10px;
  background-color: #e0e0e0;
  border-radius: 5px;
  overflow: hidden;
}

.goal-progress-bar {
  height: 100%;
  background: linear-gradient(to right, #3498db, #2ecc71);
  border-radius: 5px;
  transition: width 0.5s ease-in-out;
}

/* Dark theme styles */
.dark .goal-progress-bar-container {
  background-color: #444;
}

.dark .goal-progress-bar {
  background: linear-gradient(to right, #2980b9, #27ae60);
}


.weight-adjust-container {
  display: flex;
  align-items: center;
}

.weight-adjust-button {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: none;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin: 0 5px;
  color: white;
}

.weight-input-form {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.date-picker-container {
  display: flex;
  align-items: center;
  gap: 5px;
}

.date-picker-container button {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: none;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: var(--primary-color);
  color: white;
  transition: background-color 0.3s ease;
}

.date-picker-container button:hover {
  background-color: var(--secondary-color);
}

.weight-input-container {
  display: flex;
  align-items: center;
  gap: 10px;
}

.submit-button {
  padding: 10px 20px;
  font-size: 16px;
  border-radius: 20px;
  border: none;
  background-color: var(--primary-color);
  color: white;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.submit-button:hover {
  background-color: var(--secondary-color);
}

.weight-adjust-container {
  display: flex;
  align-items: center;
}

.weight-adjust-button {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  border: none;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin: 0 5px;
  color: white;
  transition: all 0.3s ease;
}

.weight-adjust-button:hover {
  transform: scale(1.1);
}

.weight-adjust-button.increase {
  background-color: #64c479; /* Softer pastel green */
}

.weight-adjust-button.decrease {
  background-color: #e46370; /* Softer pastel red */
}

.weight-adjust-button.increase:hover {
  background-color: #78ee78; /* Light green */
}

.weight-adjust-button.decrease:hover {
  background-color: #fa7e7c; /* Light red */
}

.weight-input-number {
  width: 80px;
  text-align: center;
  font-size: 16px;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

/* Dark theme styles */
.dark-theme .weight-adjust-button {
  color: #ffffff;
}

.dark-theme .weight-adjust-button.increase {
  background-color: #3ca23c; /* Darker green for dark theme */
}

.dark-theme .weight-adjust-button.decrease {
  background-color: #ab3f3f; /* Darker red for dark theme */
}

.dark-theme .weight-adjust-button.increase:hover {
  background-color: #90ee90; /* Light green */
}

.dark-theme .weight-adjust-button.decrease:hover {
  background-color: #ffcccb; /* Light red */
}

.dark-theme .weight-input-number {
  background-color: #444;
  color: #fff;
  border: 1px solid #555;
}

.dark-theme .submit-button,
.dark-theme .date-picker-container button {
  background-color: #0A84FF;
}

.dark-theme .submit-button:hover,
.dark-theme .date-picker-container button:hover {
  background-color: #64D2FF;
}


.help-tip {
  background-color: #FFE4B5;  /* Light orange */
  border-radius: 10px;  /* Round edges */
  color:rgb(78, 78, 78);
  padding: 10px 10px 12px 11px;
  margin-top: 5px;
  font-weight: bold;
  font-size: 1.5em;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.weight-input-wrapper {
  position: relative;
}

.weight-input-wrapper .help-tip {
  top: 100%;
  margin-top: 5px;
}

.goal-weight-container {
  position: relative;
}

.goal-weight-container .help-tip {
  position: absolute;
  right: 0;
  top: 100%;
  margin-top: 5px;
}


.made-by {
  display: flex;
  align-items: center;
  width: 240px;
}

.social-icon {
  width: 20px;
  height: 24px;
  margin-left: 10px;
  margin-right: 10px;
  filter: invert(0.5);  /* This will make the icons white. Remove if you want them black. */
}

/* If your app has a dark mode, you might want to add this: */
.dark .social-icon {
  filter: invert(0.7);  /* This will keep the icons white in dark mode */
}

/* Scaling down LinkedIn and Twitter icons */
.social-icon[alt="LinkedIn"],
.social-icon[alt="X (Twitter)"] {
  width: 15px;  /* 24px / 1.5 */
  height: 16px; /* 24px / 1.5 */
}

.prediction-row {
  display: grid;
  gap: 10px;
  grid-template-columns: repeat(3, 1fr); /* 3x2 layout by default */
}

.prediction-item {
  display: flex;
  flex-direction: column;
}

.prediction-item span:first-child {
  font-weight: bold;
}

@media (max-width: 768px) {
  .prediction-row {
    grid-template-columns: repeat(2, 1fr); /* 2x3 layout */
  }
}

@media (max-width: 525px) {
  .goal-weight-container .help-tip  {
    position: relative;
  }
  
}


@media (max-width: 480px) {
  .prediction-row {
    grid-template-columns: 1fr; /* 1x6 layout */
  }

  .date-picker-container button{
    width: 35px;
    height: 33px;
  }

  .weight-adjust-button{
    width: 65px;
    height: 50px;
    font-size: 30px;
  }

  .prediction-item {
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-size: 14px;
  }
}

.prediction-item span:last-child {
  font-weight: bold;
  color: var(--primary-color);
  transition: color 0.3s ease;
}

.prediction-item.weight-loss span:last-child.negative {
  color: #4caf50; /* Green for weight loss */
}

.prediction-item.weight-loss span:last-child.positive {
  color: #f44336; /* Red for weight gain */
}

.prediction-item.weight-gain span:last-child.negative {
  color: #f44336; /* Red for weight loss */
}

.prediction-item.weight-gain span:last-child.positive {
  color: #4caf50; /* Green for weight gain */
}


input[type="number"]#goalWeight {
  padding-right: 18px;
  padding-top: 4px;
  font-size: calc(2.5em * var(--ui-scale));
  border: 2px solid;
  width: 60px;
}

input[type="number"]#goalWeight::-webkit-inner-spin-button,
input[type="number"]#goalWeight::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.goal-weight-input-container {
  position: relative;
  display: inline-block;
}

.goal-trophy {
  position: absolute;
  right: calc(8px * var(--ui-scale));
  top: 50%;
  transform: translateY(-52%);
  font-size: calc(24px * var(--ui-scale));
  pointer-events: none;
}

/* Dark theme styles */
.dark .goal-weight-container,
.dark .weight-input-form,
.dark .time-range-selector-container {
  box-shadow: 0 5px 25px rgba(0, 0, 0, 0.26);
}

.modal {
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0,0,0,0.4);
  display: flex;
  justify-content: center;
  align-items: center;
}

.weight-conflict-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  animation: fadeIn 0.3s ease-out;
}

.weight-conflict-popup .modal-content {
  background-color: var(--background-color);
  padding: calc(30px * var(--ui-scale));
  border-radius: calc(20px * var(--ui-scale));
  box-shadow: 0 calc(4px * var(--ui-scale)) calc(6px * var(--ui-scale)) rgba(0, 0, 0, 0.1);
  text-align: center;
  max-width: 90%;
  position: relative; /* Add this line */
}
.weight-conflict-popup h2 {
  font-size: calc(2.5em * var(--ui-scale));
  color: var(--primary-color);
  margin-bottom: calc(20px * var(--ui-scale));
}

.weight-conflict-popup p {
  font-size: calc(1.2em * var(--ui-scale));
  margin-bottom: calc(15px * var(--ui-scale));
}

/* New styles for making numbers bold */
.weight-conflict-popup .weight-value {
  font-weight: bold;
  font-size: calc(1.4em * var(--ui-scale));
  color: var(--primary-color);
}

.weight-conflict-popup .button-container {
  display: flex;
  justify-content: center;
  gap: calc(15px * var(--ui-scale));
  margin-top: calc(30px * var(--ui-scale));
  flex-wrap: wrap;
}

.weight-conflict-popup button {
  padding: calc(12px * var(--ui-scale)) calc(24px * var(--ui-scale));
  border: none;
  border-radius: calc(30px * var(--ui-scale));
  cursor: pointer;
  transition: background-color 0.3s, transform 0.1s;
  font-size: calc(16px * var(--ui-scale));
  font-weight: bold;
  min-width: calc(120px * var(--ui-scale));
  height: 35px;
}

.weight-conflict-popup button:hover {
  transform: translateY(calc(-2px * var(--ui-scale)));
}

.weight-conflict-popup button:active {
  transform: translateY(0);
}

.weight-conflict-popup button.average {
  background-color: var(--primary-color);
  color: white;
}

.weight-conflict-popup button.new {
  background-color: #4caf50;
  color: white;
}

.weight-conflict-popup button.keep {
  background-color: #ff4d4d;
  color: white;
}

.weight-conflict-popup button.cancel {
  background-color: #ff4d4d;
  color: white;
}

/* Dark theme styles */
.dark .weight-conflict-popup .modal-content {
  background-color: #333333;
  color: #ffffff;
}

.dark .weight-conflict-popup h2 {
  color: #5AB3E6;
}

.dark .weight-conflict-popup .weight-value {
  color: #5AB3E6;
}

@media (max-width: 525px) {
  .weight-conflict-popup .button-container {
    flex-direction: column;
    align-items: stretch;
  }

  .weight-conflict-popup button {
    width: 100%;
    margin-bottom: calc(10px * var(--ui-scale));
  }
}

.weight-conflict-popup h2{
  font-size: 25px;
}
.weight-conflict-popup p:first-of-type{
  font-size: 14px;
  font-weight: 500;
}
.weight-conflict-popup p{
  font-size: 16px;
}
